
import preline from "@innovdata/preline";
import { Loader } from "./base/loader";
import { BackTop } from "./base/backTop";
import { VerticalImage, FullTextImages } from "./base/images";
import Toggle from "./toggle";
import IMask from 'imask';
import Menu from "./menu";
import { FiltersTable } from "./filters";
import { Select, SelectCustom } from "./select";
import { Tooltip } from "./tooltip";
import { DateRangeSelector, DateDefault } from './dateRangeSelector';
import { dualListBoxConfig, resetForm, alertClasses } from './utils';
import { Tabs } from "./tabs";
import Swal from "sweetalert2";
import DualListbox from "@innovdata/dual-listbox/src/dual-listbox";
import 'animate.css';
import './slider';
import './uppy';
import { Uploader, options as uploaderOptions } from './uploader';
import tippy from 'tippy.js';

/**
 * Recherche une entreprise dans le menu
 */
if (document.getElementById('searchEntreprise') && document.querySelector('.menu__entreprise')) {

    document.getElementById('searchEntreprise').addEventListener('input', (e) => {

        e.stopPropagation();
        e.preventDefault();

        const value = e.currentTarget.value;
        document.querySelectorAll('.menu__entreprise .dropdown__item').forEach((elm) => {

            if (elm.textContent.toLowerCase().includes(value.toLowerCase()) || value == '') {
                elm.style.display = '';
            } else {
                elm.style.display = 'none';
            }

        });
    });
}

FiltersTable();
Menu('menu');

const menusActive = (document.getElementById('menuActive') ? document.getElementById('menuActive').value : '').split('.');
const root = document.documentElement;

document.querySelectorAll('.menu .link-menu').forEach((elm) => {
    elm.addEventListener('click', (e) => {
        if (e.ctrlKey || e.metaKey) {
            // Allow normal behavior for Ctrl + Click
            return;
        }

        new Loader().show();
    });
})

menusActive.forEach((menuActive) => {

    if (['pv', 'entreprises', 'gestion_users', 'mon_compte', 'dev', 'actualite'].includes(menuActive)) {
        root.style.setProperty('--color-page', 'var(--color2-yellow-400)');
    }

    if (['obliges_imports', 'obliges_exports', 'precontrole_devis', 'reddition_compte', 'export_comptable', 'export_comptable_mpr', 'facturation_mpr', 'approbation_aaf_mpr', 'folder_cee', 'appels_facturation', 'appels_facturation_mpr', 'facture_to_validate_mpr', 'facture_to_validate', 'dossiers_cee', 'dossiers_mpr', 'pv_chantier'].includes(menuActive)) {
        root.style.setProperty('--color-page', 'var(--color2-green-500)');
    }

    if (['flux_comptable'].includes(menuActive)) {
        root.style.setProperty('--color-page', 'var(--color1-500)');
    }

    if (['admin', 'mandats', 'super_admin'].includes(menuActive)) {
        root.style.setProperty('--color-page', 'var(--color2-red-500)');
    }

});

if (document.querySelector('#loader')) {
    setTimeout(() => {
        if (document.getElementById('loaderWait') && document.getElementById('loaderWait').value == '1') {
            return;
        }
        new Loader().hide();
    }, 200);
}

/**
 * Change mdp pour les users qui n'en ont pas
 */
if(document.getElementById('formChangePWDUserNoPWD')) {

    document.getElementById('formChangePWDUserNoPWD').addEventListener('submit', async (e) =>{

        e.preventDefault();
        e.stopPropagation();

        const formData = new FormData(document.getElementById('formChangePWDUserNoPWD'));
        document.querySelector('#formChangePWDUserNoPWD .error-msg').style.display = 'none';

        const resp = await fetch(Routing.generate('compte', { from: 'js' }),{
            method: 'POST',
            body: formData
        });

        if(resp.status == 200) {
            const data = await resp.json();
            if(!data.error) {
                HSOverlay.close(document.getElementById('modalEnterPassword'));
            } else {
                document.querySelector('#formChangePWDUserNoPWD .error-msg').style.display = 'block';
            }
        }

    });
}

/**
 * MODAL CHANGE MDP
 */
if(document.getElementById('modalEnterPassword') && !document.body.classList.contains('body--login')) {
    HSOverlay.open(document.getElementById('modalEnterPassword'));
}

/**
 * Open modal subscribe kpi
 */
if (document.querySelectorAll('.btn-subscribe-kpi')) {
    document.querySelectorAll('.btn-subscribe-kpi').forEach((elm) => {
        elm.addEventListener('click', () => {
            HSOverlay.open(document.getElementById('modalSubscribeKpi'));
        });
    });
}

/**
 * Subscribe kpi cee
 */
if (document.querySelectorAll('#modalSubscribeKpi .toggle-subscribe-cee')) {
    document.querySelectorAll('#modalSubscribeKpi .toggle-subscribe-cee').forEach((elm) => {
        new Toggle(elm);
    });
}


/**
 * Frequency kpi cee
 */
if (document.querySelectorAll('#modalSubscribeKpi .select-frequency')) {
    document.querySelectorAll('#modalSubscribeKpi .select-frequency').forEach((elm) => {
        SelectCustom(elm);
    });
}


/**
 * Form submit kpi cee
 */
if (document.getElementById('formSubscribeKpi')) {
    document.getElementById('formSubscribeKpi').addEventListener('submit', async (e) => {
        e.preventDefault();

        new Loader().show();
        const formData = new FormData(document.getElementById('formSubscribeKpi'));
        const resp = await fetch(Routing.generate('fournisseurs_dossier_cee_subscribe'),{
            method: 'POST',
            body: formData
        });

        if (resp.ok) {
            const data = await resp.json();
            if (data.success) {
                Swal.fire({
                    ...alertClasses,
                    title: '',
                    text: 'Vos préférences d\'abonnements pour les indicateurs ont été enregistrées.',
                    showDenyButton: true,
                    showCloseButton: true,
                    showConfirmButton: false,
                    denyButtonText: 'Fermer'
                });
                HSOverlay.close(document.getElementById('modalSubscribeKpi'));
            }
            new Loader().hide();
        } else {
            new Loader().hide();
        }

    });
}

if (document.querySelector('.elision__help')) {

    tippy(document.querySelector('.elision__help'), {
        arrow: false,
        placement: 'left',
        content: 'Guide d\'utilisation'
    });

}
